import React, {useEffect} from 'react';
import Theme, {OverviewPage} from './theme'
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import Store from "./Store";
import { createBrowserHistory } from 'history';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "./reducers/DataReducer"
import sanityclient from './client';
import { Grid, Col, Row} from './theme'

import './assets/fonts/'
import BuilderTheme from './services/Buildertheme'
import Home from './components/Home'
import Nav from './components/nav'
import About from './components/about'
import Portfolio from './components/portfolio'
import Project from './components/project'


const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 

    if (!data.theme && dispatch ){
      const builder = new BuilderTheme();
      dispatch({type: DATA_ACTIONS.SET_THEME, theme: new BuilderTheme()})
    }
  })
  return (
    null
  )
}


const App =()=>{
  const history = createBrowserHistory()
  const theme = new BuilderTheme();

  return (
    <Provider store={Store}>
    <DataHelper />
      <Router history={history}>
        <Grid>
            <Row center="xs">
              <Col xs={12} sm={12} md={11} lg={10} xl={1}>
              
              <Nav />
            
    
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exacact path="/overview" component={OverviewPage} />
              <Route exacact path="/about" component={About} />
              <Route exacact path="/projects" component={Portfolio} />
              <Route exacact path="/project/:slug" component={Project} />
              <Route exacact path="/project" component={Project} />
              <Route component={Home}/>
            </Switch>
              </Col>
            </Row>
        </Grid>
      </Router>
  </Provider>
  );
}

export default App;
