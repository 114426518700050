import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import { Col, Row} from '../theme'


const Home = ()=>{
    const [theme, settings, projects] = useSelector(state => [state.data.theme, state.data.settings, state.data.projects]);
    const history = useHistory()


    useEffect(()=>{
    })

    if (!theme || !settings || !projects){
      return null;
    }


    const getPath = (i)=>{
      const v = projects[i];
      return `/project/:${projects[i].slug}`
    }

    const content = ()=><Carousel autoPlay={true} infiniteLoop={true} showArrows={false} showIndicators={false} showThumbs={false} showStatus={false} onClickItem	={(i)=>{
                            history.push(getPath(i))
                      }} >
                      {projects.map(({cover, title}, i)=>{
                      return <NavLink to={getPath(i)} key={title}  >
                        <img src={cover} />
                        <theme.Text>
                          <p style={{align: "left", textAlign: "left"}}> {title} </p>
                        </theme.Text>
                      </NavLink>
                      })}
                      </Carousel>
    
    
    return <>
        <MetaTags>
                <title>{ `${settings.title}`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.about)} />
                <meta property="og:title" content={settings.title} />
                <meta property="og:image" content={settings.default+"?w=720"} /> 
            </MetaTags>

            <br/><br/><br/>

            <Row>
              <Col xs={12} sm={false} md={false} large={false} style={{
                position: 'absolute', left: '50%', top: '50%',
                transform: 'translate(-50%, -50%)'
              }}>
                {content()}
              </Col>
              <Col xs={false} sm={12} md={12} large={12}>
                {content()}
              </Col>
            </Row>
    </>
  }

export default Home;