import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

const Nav = ()=>{
    const theme = useSelector(state => state.data.theme);
    const settings = useSelector(state=>state.data.settings);
    const location = useLocation();

    useEffect(()=>{
      console.log(location.pathname);
    })

    if (!theme || !settings){
      return null;
    }

    const getFirst = ()=>{
      switch(location.pathname){
        
        case "/projects":
        case "/about":
        return <NavLink to="/">
        {settings.title}
      </NavLink>  
        case "/": 
        default: 
        return <NavLink to="/about">
          {settings.title}
        </NavLink>  
            
      }
    }

    const getSecond = ()=>{
      switch(location.pathname){
        case "/projects": 
          return <NavLink to="/about">
          About
        </NavLink>
        default: 
            return <NavLink to="/projects">
                Projects
              </NavLink>
      }

    }

    return <theme.Text >
        <div >
              <p style={{float: "left"}}>
                {getFirst()}
              </p>
                

              <p style={{float: "right"}}>
              {getSecond()}
                
              </p>
            </div>
        
    </theme.Text>
        
  
  }

export default Nav;