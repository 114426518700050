import React, {useState, useEffect, useRef} from 'react';
import { Col, Row} from '../theme'
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';

const Portfolio = ()=>{
    const [theme, settings, projects] = useSelector(state => [state.data.theme, state.data.settings, state.data.projects]);
    
    

    useEffect(()=>{
    //   console.log(about)
    })

    if (!theme || !projects || !settings){
        return null;
    }

    return <> 

            <MetaTags>
                <title>{ `${settings.title} / projects`}</title>
                <meta property="og:title" content={ `${settings.title} / projects`} />
                <meta property="og:image" content={settings.default+"?w=720"} /> 
            </MetaTags>

    <br/><br/><br/>

    
    
    <Row center="xs">
        {projects.map(({cover, title, slug})=>{
            return <Col xs={12} sm={6} md={6} lg={6} key={slug}>
                <NavLink to={`/project/:${slug}`}>
                    
                        <img src={cover} alt={title} style={{width: "100%"}} />
                        <theme.Text>
                            <p style={{align: "left", textAlign: "left"}}> {title} </p>
                        </theme.Text>

                    
                </NavLink>
            </Col>
        })}
    </Row>

    </>
        
  
  }

export default Portfolio;