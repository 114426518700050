import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
// import toast from '../services/toast'
import imageUrlBuilder from '@sanity/image-url'

const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings;
    let news = [];
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: false
    })

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    
    if (environment.dev){
        environment.printstatus()
    } 

    // about
    fetch('*[_type == "siteSettings"][0]{about, title, "default":default.asset->url}').then((settings)=>{
      dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings});
    })

    fetch('*[_type == "portfolio"][0]{projects[]->{title, "slug":slug.current, description, "cover":cover.asset->url, "images":images[].asset->url}}').then(({projects})=>{
        dispatch({type: DATA_ACTIONS.SET_PROJECTS, projects});
    })

    // title, description, 
    // fetch("*[_type == 'biomoddnews']{title, description, images[]{title, description, 'image':image.asset->url}} | order(_createdAt desc)").then((news)=>{
    //     console.log(news)
    //     dispatch({ type: DATA_ACTIONS.SET_NEWS, news});
    // })

    

    return {
        fetch,
        environment,
        squareImage
    }
}



export default client;

