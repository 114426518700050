import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import BlockContent from '@sanity/block-content-to-react';
import { Col, Row} from '../theme'
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'

const About = ()=>{
    const [theme, settings] = useSelector(state => [state.data.theme, state.data.settings]);

    useEffect(()=>{
    //   console.log(about)
    })

    if (!theme || !settings){
        return null;
    }

    return <>
    <br/><br/><br/>
    <Row>
        <MetaTags>
                <title>{ `${settings.title} / about`}</title>
                 <meta name="description" content={BlocktoPlainText(settings.about)} />
                <meta property="og:title" content={`${settings.title} / about`} />
                <meta property="og:image" content={settings.default+"?w=720"} /> 
            </MetaTags>
        <Col xs={12} md={8} lg={7}>
            <theme.Container>
                <br/><br/>
                <theme.Text>
                    <BlockContent blocks={settings.about} />
                </theme.Text>
            </theme.Container>
        </Col>
    </Row>  
    </>
  }

export default About;