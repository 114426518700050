import React, {useState, useEffect, useRef} from 'react';
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText'
import { useHistory } from "react-router-dom"
import { Col, Row} from '../theme'
import BlockContent from '@sanity/block-content-to-react';

const Project = (props)=>{
    const [theme, settings, projects] = useSelector(state => [state.data.theme, state.data.settings, state.data.projects]);
    const [project, setProject] = useState(null);
    const history = useHistory()

    useEffect(()=>{
        if(!project && projects){
            const s = props.match.params.slug;
            let i = null;
            projects.forEach((p, idx)=>{
                if (":"+p.slug === s){
                    i = p;
                }
            })
            if (i){
                setProject(i);
            } else {
                history.push("/")
            }
        }  
    })

    const getPrevNext = ()=>{

        let idx = null;

        projects.forEach((p, i)=>{
            if (p.slug == project.slug){
                idx = i;
            }
        })

        let [prev, next] = [idx-1, idx+1];

        if (prev < 0){
            prev = projects.length-1;
        }

        if (next > projects.length-1){
            next = 0;
        }

        return [projects[prev], projects[next]];

    }


    if (!theme || !settings || !projects || !project){
        return null;
      }

    return <>
        <MetaTags>
            <title>{ `${settings.title} / ${project.title}`}</title>
             <meta name="description" content={BlocktoPlainText(settings.about)} />
            <meta property="og:title" content={`${settings.title} / ${project.title}`} />
            <meta property="og:image" content={project.cover+"?w=720"} /> 
        </MetaTags>
        <br/><br/><br/>
        <Row>
            <Col xs={12}>
                <img src={project.cover} alt={project.title} style={{width: "100%"}} />
            </Col>
            {project.images.map(i=>
            <Col xs={12} key={i}>
                <br/>
                <img src={i}  alt={i} style={{width: "100%"}} />
            </Col>)}
        </Row>
        <Row>
            <Col xs={12} md={10} lg={8}>
                <theme.Text>
                    <br/>
                    {project.title} 
                    <BlockContent blocks={project.description?project.description:''} />
                    <br/><br/>
                </theme.Text>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={false} sm={false} md={true} lg={true}>
                <Row center="xs">
                {getPrevNext().map(({cover, title, slug})=>{
                    return <Col xs={12} sm={6} md={6} lg={6} key={slug}>
                        <NavLink to={`/project/:${slug}`} onClick={()=>{
                            setProject(null);
                            window.scrollTo(0, 0)
                        }}>
                            
                                <img src={cover} alt={title} style={{width: "100%"}} />
                                <theme.Text>
                                    <p style={{align: "left", textAlign: "left"}}> {title} </p>
                                </theme.Text>
                            
                        </NavLink>
                </Col>
                })}
                </Row>
            </Col>
            <Col xs={12} sm={12} md={false} lg={false}>
                <theme.Text>
                    <p style={{float: "left"}}>
                    <NavLink to={`/project/:${getPrevNext()[0].slug}`} onClick={()=>{
                            setProject(null);
                            window.scrollTo(0, 0)
                        }}>
                            previous project
                    </NavLink>
                    </p>
                    <p style={{float: "right"}}>
                    <NavLink to={`/project/:${getPrevNext()[1].slug}`} onClick={()=>{
                            setProject(null);
                            window.scrollTo(0, 0)
                        }}>
                            next project
                    </NavLink>
                    </p>
                </theme.Text>
            </Col>
    </Row>
    <br/>
        
    </>
        
  
  }

export default Project;