export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_THEME: Symbol('data/theme'),
    SET_THEMES: Symbol('data/themes'),
    SET_PROJECTS: Symbol('data/projects'),
    SET_SETTINGS: Symbol('data/settings'),
});

const initialState = {
  client: null,
  theme: null,
  themes: null,
  projects: null,
  settings: null,
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_PROJECTS: {
      return {
        ...state,
        projects: action.projects
      };
    }
    case ACTIONS.SET_SETTINGS: {
      return {
        ...state,
        settings: action.settings
      };
    }
    case ACTIONS.SET_THEME: {
      return {
        ...state,
        theme: action.theme
      };
    }
    case ACTIONS.SET_THEMES: {
      return {
        ...state,
        themes: action.themes
      };
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
